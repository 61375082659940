import { gql } from '@apollo/client';

export const CreateDataSharing = gql`
  mutation CreateDataSharing($body: CreateDataSharingBody!) {
    createDataSharing(body: $body)
      @rest(
        type: "CreateDataSharing"
        path: "/core/client/data_sharings"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "DataSharing") {
        accepted_at
        case_projections_count
        created_at
        data_sharing_key @type(name: "DataSharingKey") {
          created_at
          expired_at
          id
          updated_at
          value
        }
        data_sharing_rules @type(name: "DataSharingRule") {
          allowed_actions
          created_at
          data_sharing_id
          data_sharing_rule_cases_count
          enabled
          frontend_type
          id
          name
          sharing_additional_case_ids
          sharing_entities
          sharing_filters
          updated_at
        }
        expired_at
        id
        message
        receiver_email
        sender_account @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        sender_client @type(name: "Client") {
          file_name
          file_size
          id
          logo_id
          name
        }
        receiver_account @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        receiver_client @type(name: "Client") {
          file_name
          file_size
          id
          logo_id
          name
        }
        state
        updated_at
        warning_code
        warning_title
      }
      success
    }
  }
`;

export const UpdateDataSharing = gql`
  mutation UpdateDataSharing($id: String!, $body: UpdateDataSharingBody!) {
    updateDataSharing(id: $id, body: $body)
      @rest(
        type: "UpdateDataSharing"
        path: "/core/client/data_sharings/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "DataSharing") {
        accepted_at
        case_projections_count
        created_at
        data_sharing_key @type(name: "DataSharingKey") {
          created_at
          expired_at
          id
          updated_at
          value
        }
        data_sharing_rules @type(name: "DataSharingRule") {
          allowed_actions
          created_at
          data_sharing_id
          data_sharing_rule_cases_count
          enabled
          frontend_type
          id
          name
          sharing_additional_case_ids
          sharing_entities
          sharing_filters
          updated_at
        }
        expired_at
        id
        message
        receiver_email
        sender_account @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        sender_client @type(name: "Client") {
          file_name
          file_size
          id
          logo_id
          name
        }
        receiver_account @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        receiver_client @type(name: "Client") {
          file_name
          file_size
          id
          logo_id
          name
        }
        state
        updated_at
        warning_code
        warning_title
      }
      success
    }
  }
`;

export const CreateDataSharingRule = gql`
  mutation CreateDataSharingRule($body: CreateOrUpdateDataSharingRuleBody!) {
    createDataSharingRule(body: $body)
      @rest(
        type: "CreateDataSharingRule"
        path: "/core/client/data_sharing_rules"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "DataSharingRule") {
        allowed_actions
        created_at
        data_sharing_id
        data_sharing_rule_cases_count
        enabled
        frontend_type
        id
        name
        sharing_additional_case_ids
        sharing_entities
        sharing_filters
        updated_at
      }
      success
    }
  }
`;

export const UpdateDataSharingRule = gql`
  mutation UpdateDataSharingRule(
    $id: String!
    $body: CreateOrUpdateDataSharingRuleBody!
  ) {
    updateDataSharingRule(id: $id, body: $body)
      @rest(
        type: "UpdateDataSharingRule"
        path: "/core/client/data_sharing_rules/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "DataSharingRule") {
        allowed_actions
        created_at
        data_sharing_id
        data_sharing_rule_cases_count
        enabled
        frontend_type
        id
        name
        sharing_additional_case_ids
        sharing_entities
        sharing_filters
        updated_at
      }
      success
    }
  }
`;

export const DeleteDataSharingRule = gql`
  mutation DeleteDataSharingRule($id: String!) {
    deleteDataSharingRule(id: $id)
      @rest(
        type: "UpdateDataSharingRule"
        path: "/core/client/data_sharing_rules/{args.id}"
        method: "DELETE"
      ) {
      success
    }
  }
`;

export const ChangeStateDataSharing = gql`
  mutation ChangeStateDataSharing(
    $id: String!
    $body: ChangeStateDataSharingBody!
  ) {
    changeStateDataSharing(id: $id, body: $body)
      @rest(
        type: "ChangeStateDataSharing"
        path: "/core/client/data_sharings/{args.id}/change_state"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "DataSharing") {
        accepted_at
        case_projections_count
        created_at
        data_sharing_key @type(name: "DataSharingKey") {
          created_at
          expired_at
          id
          updated_at
          value
        }
        data_sharing_rules @type(name: "DataSharingRule") {
          allowed_actions
          created_at
          data_sharing_id
          data_sharing_rule_cases_count
          enabled
          frontend_type
          id
          name
          sharing_additional_case_ids
          sharing_entities
          sharing_filters
          updated_at
        }
        expired_at
        id
        message
        receiver_email
        sender_account @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        sender_client @type(name: "Client") {
          file_name
          file_size
          id
          logo_id
          name
        }
        receiver_account @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        receiver_client @type(name: "Client") {
          file_name
          file_size
          id
          logo_id
          name
        }
        state
        updated_at
        warning_code
        warning_title
      }
      success
      errors
    }
  }
`;

export const ChangeStateCustomerDataSharing = gql`
  mutation ChangeStateCustomerDataSharing(
    $id: String!
    $body: ChangeStateCustomerDataSharingBody!
  ) {
    changeStateCustomerDataSharing(id: $id, body: $body)
      @rest(
        type: "ChangeStateCustomerDataSharing"
        path: "/core/customer/data_sharings/{args.id}/change_state"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "DataSharing") {
        accepted_at
        case_projections_count
        created_at
        data_sharing_key @type(name: "DataSharingKey") {
          created_at
          expired_at
          id
          updated_at
          value
        }
        data_sharing_rules @type(name: "DataSharingRule") {
          allowed_actions
          created_at
          data_sharing_id
          data_sharing_rule_cases_count
          enabled
          frontend_type
          id
          name
          sharing_additional_case_ids
          sharing_entities
          sharing_filters
          updated_at
        }
        expired_at
        id
        message
        receiver_email
        sender_account @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        sender_client @type(name: "Client") {
          file_name
          file_size
          id
          logo_id
          name
        }
        receiver_account @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        receiver_client @type(name: "Client") {
          file_name
          file_size
          id
          logo_id
          name
        }
        state
        updated_at
        warning_code
        warning_title
      }
      success
    }
  }
`;

export const SharingUploadDocument = gql`
  mutation SharingUploadDocument(
    $file: Upload!
    $kind: String!
    $service: String
    $data_sharing_id: String!
  ) {
    sharingUploadDocument(
      file: $file
      kind: $kind
      service: $service
      data_sharing_id: $data_sharing_id
    ) {
      data @type(name: "Document") {
        file_size
        file_path
        file_name
        safe_upload_url
        safe_download_url
        av_status
        id
      }
      errors
      success
    }
  }
`;
