import gql from 'graphql-tag';
import { IDENTITY_VERIFICATION_FRAGMENT } from '../../fragment/identityVerification';

export const IdentityVerifications = gql`
  ${IDENTITY_VERIFICATION_FRAGMENT}
  query IdentityVerifications(
    $name: String
    $surname: String
    $date_of_birth_at: String
    $sort_field: String
    $sort_order: String
    $country: String
    $status: String
    $q: String
    $page: Int
    $per: Int = 20
  ) {
    identityVerifications(
      name: $name
      surname: $surname
      date_of_birth_at: $date_of_birth_at
      country: $country
      status: $status
      page: $page
      sort_field: $sort_field
      sort_order: $sort_order
      per: $per
      q: $q
    )
      @rest(
        type: "ResponseIdentityVerifications"
        path: "/core/client/idv_result_registries?{args}"
      ) {
      data @type(name: "IdentityVerificationItem") {
        ...IdentityVerificationFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const IdentityVerificationsPaggination = gql`
  ${IDENTITY_VERIFICATION_FRAGMENT}
  query IdentityVerificationsPaggination(
    $name: String
    $surname: String
    $date_of_birth_at: String
    $sort_field: String
    $sort_order: String
    $country: String
    $status: String
    $q: String
    $page: Int
    $per: Int = 20
  ) {
    identityVerificationsPaggination(
      name: $name
      surname: $surname
      date_of_birth_at: $date_of_birth_at
      country: $country
      status: $status
      page: $page
      sort_field: $sort_field
      sort_order: $sort_order
      per: $per
      q: $q
    )
      @rest(
        type: "ResponseIdentityVerifications"
        path: "/core/client/idv_result_registries?{args}"
      ) {
      data @type(name: "IdentityVerificationItem") {
        ...IdentityVerificationFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const IdentityVerification = gql`
  ${IDENTITY_VERIFICATION_FRAGMENT}
  query IdentityVerification($id: String!) {
    identityVerification(id: $id)
      @rest(
        type: "ResponseIdentityVerification"
        path: "/core/client/idv_result_registries/{args.id}"
      ) {
      data @type(name: "IdentityVerificationItem") {
        ...IdentityVerificationFragment
      }
      success
    }
  }
`;
