import gql from 'graphql-tag';
import { CHECKUP_FRAGMENT } from '../../../fragment/admin/checkups';
import { SERVICE_CHECKUP_FRAGMENT } from '../../../fragment/checkup/ServiceCheckup';
import { VERIFF_RESULT_FRAGMENT } from '../../../fragment/pdf/case/identityVerifications/VeriffResult';

export const ClientCheckups = gql`
  ${CHECKUP_FRAGMENT}
  query ClientCheckups(
    $page: Int
    $q: String
    $price_min: Float
    $price_max: Float
  ) {
    clientCheckups(
      page: $page
      per: 20
      q: $q
      price_min: $price_min
      price_max: $price_max
    )
      @rest(
        type: "ResponseClientCheckups"
        path: "/core/client/checkups?{args}"
      ) {
      data @type(name: "Checkup") {
        ...CheckupFragment
        required_field_codes
        settings
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const ClientCheckup = gql`
  ${CHECKUP_FRAGMENT}
  query ClientCheckup($id: String!) {
    clientCheckup(id: $id)
      @rest(
        type: "ResponseClientCheckup"
        path: "/core/client/checkups/{args.id}"
      ) {
      data @type(name: "Checkup") {
        ...CheckupFragment
        required_field_codes
        settings
      }
      success
    }
  }
`;

export const ServiceCheckups = gql`
  ${SERVICE_CHECKUP_FRAGMENT}
  query ServiceCheckups(
    $page: Int
    $q: String
    $case_id: String
    $checkup_kind_code: String
    $checkup_kind_codes: String
    $fincen_list_ids: String
    $ids: String
    $resolutions: String
    $execution_statuses: String
    $audit_statuses: String
    $provider_statuses: String
    $external_ref_entity_types: String
    $external_ref_entity_id: String
    $created_at_from: String
    $created_at_to: String
    $last_screened_at_from: String
    $last_screened_at_to: String
    $assigned_to_id: String
    $sort_field: String
    $sort_order: String
    $case_status: String
    $cryptocurrency_symbol: String
    $blockchain: String
    $retention_plan_id: String
    $without_data_sharing_id: Boolean
    $per: Int
  ) {
    serviceCheckups(
      page: $page
      q: $q
      case_id: $case_id
      checkup_kind_code: $checkup_kind_code
      checkup_kind_codes: $checkup_kind_codes
      fincen_list_ids: $fincen_list_ids
      without_data_sharing_id: $without_data_sharing_id
      ids: $ids
      resolutions: $resolutions
      execution_statuses: $execution_statuses
      audit_statuses: $audit_statuses
      provider_statuses: $provider_statuses
      external_ref_entity_types: $external_ref_entity_types
      external_ref_entity_id: $external_ref_entity_id
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      last_screened_at_from: $last_screened_at_from
      last_screened_at_to: $last_screened_at_to
      sort_field: $sort_field
      sort_order: $sort_order
      assigned_to_id: $assigned_to_id
      case_status: $case_status
      cryptocurrency_symbol: $cryptocurrency_symbol
      retention_plan_id: $retention_plan_id
      blockchain: $blockchain
      per: $per
    )
      @rest(
        type: "ResponseServiceCheckups"
        path: "/checkups/client/checkups?{args}"
      ) {
      data @type(name: "ServiceCheckup") {
        ...ServiceCheckupFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const ServiceCheckupsPaggination = gql`
  ${SERVICE_CHECKUP_FRAGMENT}
  query ServiceCheckupsPaggination(
    $page: Int
    $per: Int
    $q: String
    $case_id: String
    $checkup_kind_code: String
    $checkup_kind_codes: String
    $fincen_list_ids: String
    $ids: String
    $resolutions: String
    $execution_statuses: String
    $audit_statuses: String
    $provider_statuses: String
    $external_ref_entity_types: String
    $created_at_from: String
    $created_at_to: String
    $last_screened_at_from: String
    $last_screened_at_to: String
    $assigned_to_id: String
    $without_data_sharing_id: Boolean
    $sort_field: String
    $sort_order: String
    $aml_types: String
    $case_status: String
  ) {
    serviceCheckupsPaggination(
      page: $page
      per: $per
      q: $q
      case_id: $case_id
      checkup_kind_code: $checkup_kind_code
      checkup_kind_codes: $checkup_kind_codes
      without_data_sharing_id: $without_data_sharing_id
      fincen_list_ids: $fincen_list_ids
      ids: $ids
      resolutions: $resolutions
      execution_statuses: $execution_statuses
      audit_statuses: $audit_statuses
      provider_statuses: $provider_statuses
      external_ref_entity_types: $external_ref_entity_types
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      last_screened_at_from: $last_screened_at_from
      last_screened_at_to: $last_screened_at_to
      sort_field: $sort_field
      sort_order: $sort_order
      assigned_to_id: $assigned_to_id
      aml_types: $aml_types
      case_status: $case_status
    )
      @rest(
        type: "ResponseServiceCheckups"
        path: "/checkups/client/checkups?{args}"
      ) {
      data @type(name: "ServiceCheckup") {
        ...ServiceCheckupFragment
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const ServiceCheckup = gql`
  ${SERVICE_CHECKUP_FRAGMENT}
  query ServiceCheckup($id: String!) {
    serviceCheckup(id: $id)
      @rest(
        type: "ResponseClientCheckup"
        path: "/checkups/client/checkups/{args.id}"
      ) {
      data @type(name: "ServiceCheckup") {
        ...ServiceCheckupFragment
        form
        serialized_result
      }
      success
    }
  }
`;

export const VeriffCheckup = gql`
  ${VERIFF_RESULT_FRAGMENT}
  query VeriffCheckup($id: String!) {
    veriffCheckup(id: $id)
      @rest(
        type: "ResponseVeriffCheckup"
        path: "/core/client/veriff_results/{args.id}"
      ) {
      data @type(name: "VeriffResult") {
        ...VeriffResultFragment
      }
      success
    }
  }
`;

export const CheckupsReport = gql`
  query CheckupsReport(
    $q: String
    $checkup_kind_code: String
    $checkup_kind_codes: String
    $fincen_list_ids: String
    $ids: String
    $resolutions: String
    $execution_statuses: String
    $audit_statuses: String
    $provider_statuses: String
    $external_ref_entity_types: String
    $created_at_from: String
    $created_at_to: String
    $last_screened_at_from: String
    $last_screened_at_to: String
    $sort_field: String
    $sort_order: String
  ) {
    checkupsReport(
      q: $q
      checkup_kind_code: $checkup_kind_code
      checkup_kind_codes: $checkup_kind_codes
      fincen_list_ids: $fincen_list_ids
      ids: $ids
      resolutions: $resolutions
      execution_statuses: $execution_statuses
      audit_statuses: $audit_statuses
      provider_statuses: $provider_statuses
      external_ref_entity_types: $external_ref_entity_types
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      last_screened_at_from: $last_screened_at_from
      last_screened_at_to: $last_screened_at_to
      sort_field: $sort_field
      sort_order: $sort_order
    )
      @rest(
        type: "ResponseCheckupsReport"
        path: "/checkups/client/reports/checkups_csv?{args}"
      )
  }
`;
